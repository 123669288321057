import { render, staticRenderFns } from "./pageElementNumber.vue?vue&type=template&id=721d69e4&scoped=true&"
import script from "./pageElementNumber.vue?vue&type=script&lang=js&"
export * from "./pageElementNumber.vue?vue&type=script&lang=js&"
import style0 from "./pageElementNumber.vue?vue&type=style&index=0&id=721d69e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721d69e4",
  null
  
)

export default component.exports