<template>
  <div>
    <v-layout align-center>
      <v-text-field
        v-if="!item.ReadOnly && item.NeedsNumberSign && item.IsPrefix"
        :prefix="item.NumberSign"
        outlined
        dense
        step="0.01"
        v-model.number="item.Value"
        :required="item.Required"
        :disabled="
          $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
        "
        hide-details="auto"
        single-line
        :rules="$validation.getValidation(item.FieldValidationFuntion)"
        type="number"
      ></v-text-field>
      <v-text-field
        v-else-if="(!item.ReadOnly && !needsDisable)||editItemAdmin"
        outlined
        dense
        step="0.01"
        v-model.number="item.Value"
        :required="item.Required"
        :disabled="
          $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
        "
        hide-details="auto"
        single-line
        :rules="$validation.getValidation(item.FieldValidationFuntion)"
        type="number"
        :suffix="item.NeedsNumberSign ? item.NumberSign : ''"
      ></v-text-field>

      <p
        class="mb-0"
        v-else-if="
          $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
        "
      >
        <span
          class="mr-1 mb-0"
          v-if="
            item.NeedsNumberSign &&
            (item.ReadOnly || needsDisable) &&
            item.IsPrefix
          "
        >
          {{ item.NumberSign }}
        </span>
        {{ item.Value }}
        <span
          class="ml-1 mb-0"
          v-if="
            item.NeedsNumberSign &&
            (item.ReadOnly || needsDisable) &&
            !item.IsPrefix
          "
        >
          {{ item.NumberSign }}
        </span>
      </p>
      <h1 class="ml-2 mt-1" v-if="item.IsPercentage && !item.NeedsNumberSign">
        %
      </h1>
    </v-layout>
    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col cols="12">
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              dense
              :label="$t('Column width')"
              class="mt-4 mw-w"
            ></v-text-field>

            <v-switch
              v-model="item.ReadOnly"
              :label="$t('Read-only')"
              hide-details="auto"
              class="float-left mr-3"
            ></v-switch>

            <v-divider v-if="item.NeedsNumberSign" class="mt-4"></v-divider>
            <v-switch
              v-model="item.NeedsNumberSign"
              hide-details="auto"
              :label="$t('Show currency symbol')"
              class="float-left mr-3"
            >
            </v-switch>
            <v-switch
              v-if="item.NeedsNumberSign"
              v-model="item.IsPrefix"
              :label="$t('Show currency symbol in front')"
              class="float-left mr-3"
            >
            </v-switch>
            <v-select
              v-if="item.NeedsNumberSign"
              v-model="item.NumberSign"
              :items="$config.signs"
              dense
              :label="$t('Symbol')"
              class="float-left mr-3 mw-w mt-4"
            ></v-select>
            <v-divider
              v-if="item.NeedsNumberSign"
              :class="item.NeedsNumberSign ? '' : 'my-4'"
            ></v-divider>
            <v-switch
              v-model="item.FieldValidation"
              @change="$helpers.changeFieldValidation(item)"
              class="float-left mr-3"
              :label="$t('Field validation')"
            >
            </v-switch>
            <v-combobox
              v-if="item.FieldValidation"
              :items="$validation.getAllValidationNames()"
              class="float-left mr-3"
              :label="$t('Rule')"
              v-model="item.FieldValidationFuntion"
              clearable
              @click:clear="item.FieldValidationFuntion = ''"
            >
            </v-combobox>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>